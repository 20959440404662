import React from 'react';

import classNames from 'classnames/bind';

import CheckboxIcon from '@COMPONENTS/COMMON/custom-icons/CheckboxIcon';
import styles from './FakeCheckbox.module.scss';

const cx = classNames.bind(styles);

function FakeCheckbox({
    label, //
    checked,
    disabled = false,
}: Props) {
    return (
        <div
            className={cx('fake-checkbox', {
                disabled,
            })}
        >
            <div className={cx('icon-wrapper')}>
                <CheckboxIcon
                    checked={checked}
                    disabled={disabled}
                />
            </div>
            <div className={cx('label')}>{label}</div>
        </div>
    );
}

FakeCheckbox.defaultProps = {
    disabled: false,
};

interface Props {
    label: string;
    checked: boolean | 'half-checked';
    disabled?: boolean;
}

export default FakeCheckbox;
