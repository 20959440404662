/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NewVariantModalState {
    isOpen: boolean;

    data: {
        documentId: number;

        chapterId: number;

        sectionId: number;

        canEditDocument: boolean;
    } | null;
}

export interface NewVariantModalDispatchParams {
    documentId: number;

    chapterId: number;

    sectionId: number;

    canEditDocument: boolean;
}

const initialState: NewVariantModalState = {
    isOpen: false,

    data: null,
};

export const newVariantModalSlice = createSlice({
    name: 'new-variant-modal',
    initialState,
    reducers: {
        open(state: NewVariantModalState, action: PayloadAction<NewVariantModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: NewVariantModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
