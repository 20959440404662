import React from 'react';

import classNames from 'classnames/bind';

import type { NoticeProps } from 'react-select';
import { components } from 'react-select';
import styles from './NoOptionsMessageBase.module.scss';

const cx = classNames.bind(styles);

function NoOptionsMessageBase<T, IsMulti extends boolean>({ children, ...props }: NoticeProps<T, IsMulti>) {
    return (
        <components.NoOptionsMessage
            className={cx('no-options-message-base')}
            {...props}
        >
            {children}
        </components.NoOptionsMessage>
    );
}

export default NoOptionsMessageBase;
