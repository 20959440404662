import { AdminPreviewChapterSectionVariant, AdminPreviewDocumentChapterSectionVariant } from '@SERVICES';
import { SelectedOptions } from '@MODALS/Previews/components/BasicFilters/BasicFilters.component';

type Variant = AdminPreviewChapterSectionVariant | AdminPreviewDocumentChapterSectionVariant;

export default function getFilteredByOptionsPreviewVariants(variants: Variant[], selectedOptions: SelectedOptions) {
    return variants.filter((variant) => {
        if (selectedOptions.brand !== null) {
            if (!variant.brands.find((v) => v.id === selectedOptions.brand!.value)) {
                return false;
            }
        }

        if (selectedOptions.region !== null) {
            if (!variant.regions.find((v) => v.id === selectedOptions.region!.value)) {
                return false;
            }
        }

        if (selectedOptions.area !== null) {
            if (!variant.areas.find((v) => v.id === selectedOptions.area!.value)) {
                return false;
            }
        }

        if (selectedOptions.country !== null) {
            if (!variant.countries.find((v) => v.id === selectedOptions.country!.value)) {
                return false;
            }
        }

        if (selectedOptions.propertyType !== null) {
            if (!variant.propertyTypes.find((v) => v.id === selectedOptions.propertyType!.value)) {
                return false;
            }
        }

        return true;
    });
}
