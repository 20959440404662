import React, {
    RefObject, useLayoutEffect, useRef, useState,
} from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { useDownloadDocument } from '@SERVICES';

import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import {
    DocumentStep,
    BrandStep,
    RegionStep,
    AreaStep,
    CountryStep,
    TypeStep,
    OptionsStep,
    DownloadDocumentStep,
    DownloadDocumentStepData,
    SelectContentStep,
} from '@REDUCERS';
import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';

import { notify } from '@NOTIFICATION/Notificator';

import { GenerationMode } from '@PAGES/manager/options/Options.page';

import BasicSelect from 'src/view/COMPONENTS/COMMON/inputs/select/inputs/BasicSelect';
import BasicInput from '@COMPONENTS/COMMON/inputs/input/BasicInput';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import createDocumentLinkAndClick from '@HELPERS/createDocumentLinkAndClick';
import getOrigin from '@HELPERS/getOrigin';

import styles from './DownloadDocument.module.scss';

const cx: CX = classnames.bind(styles);

export enum Expiration {
    HOURS_24 = 1,
    DAYS_3 = 2,
    DAYS_7 = 3,
    DAYS_14 = 4,
    UNLIMITED = 5,
}

type TimeOption = {
    value: Expiration;

    label: string;
};

const expirationTime = [
    {
        value: Expiration.HOURS_24,
        label: '24 hours',
    },
    {
        value: Expiration.DAYS_3,
        label: '3 days',
    },
    {
        value: Expiration.DAYS_7,
        label: '7 days',
    },
    {
        value: Expiration.DAYS_14,
        label: '14 days',
    },
    {
        value: Expiration.UNLIMITED,
        label: 'Unlimited',
    },
];

const timeMap = {
    [Expiration.HOURS_24]: 24,
    [Expiration.DAYS_3]: 24 * 3,
    [Expiration.DAYS_7]: 24 * 7,
    [Expiration.DAYS_14]: 24 * 14,
    [Expiration.UNLIMITED]: 0,
};

function DownloadDocumentPage() {
    const {
        actions: documentGenerationStepsActions,
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps();

    const [
        generate,
        {
            data: downloadDocumentData, isLoading, isSuccess, isError, error,
        },
    ] = useDownloadDocument();

    const documentStep: DocumentStep = documentGenerationStepsServices.getStepData(GenerationSteps.DOCUMENT);
    const brandStep: BrandStep = documentGenerationStepsServices.getStepData(GenerationSteps.BRAND);
    const regionStep: RegionStep = documentGenerationStepsServices.getStepData(GenerationSteps.REGION);
    const areaStep: AreaStep = documentGenerationStepsServices.getStepData(GenerationSteps.AREA);
    const countryStep: CountryStep = documentGenerationStepsServices.getStepData(GenerationSteps.COUNTRY);
    const typeStep: TypeStep = documentGenerationStepsServices.getStepData(GenerationSteps.TYPE);
    const downloadDocumentStep: DownloadDocumentStep = documentGenerationStepsServices.getStepData(GenerationSteps.DOWNLOAD_DOCUMENT);
    const downloadDocumentStepData = downloadDocumentStep.data;

    const [selectedTime, setSelectedTime] = useState<TimeOption>(
        downloadDocumentStepData?.expirationTime !== undefined
            ? expirationTime.find((t) => t.value === downloadDocumentStepData.expirationTime)!
            : expirationTime[0],
    );

    const [watermarkText, setWatermarkText] = useState<string>(downloadDocumentStepData?.watermarkText || '');

    const [link, setLink] = useState(downloadDocumentStepData?.link || '');

    const linkRef: RefObject<HTMLTextAreaElement> = useRef(null);

    useLayoutEffect(() => {
        if (isSuccess) {
            const { url } = downloadDocumentData!;

            setLink(url);

            const data: DownloadDocumentStepData = {
                expirationTime: selectedTime.value,
                watermarkText,
                link: url,
            };

            documentGenerationStepsActions.apply({
                id: GenerationSteps.DOWNLOAD_DOCUMENT,
                isComplete: true,
                data,
            });
        }
    }, [documentGenerationStepsActions, selectedTime, watermarkText, downloadDocumentData, isSuccess]);

    useLayoutEffect(() => {
        if (downloadDocumentStepData) {
            if (selectedTime.value !== downloadDocumentStepData!.expirationTime) {
                setLink('');
            }
        }
    }, [selectedTime, downloadDocumentStepData]);

    useLayoutEffect(() => {
        if (downloadDocumentStepData) {
            if (watermarkText !== downloadDocumentStepData!.watermarkText) {
                setLink('');
            }
        }
    }, [watermarkText, downloadDocumentStepData]);

    useLayoutEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'download-document-error-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'download-document-error',
                });
            }
        }
    }, [isError, error]);

    function getDownloadLink() {
        if (!link) return '';

        const origin = getOrigin();

        return origin + link;
    }

    function generateDocument() {
        const optionsStep: OptionsStep = documentGenerationStepsServices.getStepData(GenerationSteps.OPTIONS);
        const selectContentStep: SelectContentStep = documentGenerationStepsServices.getStepData(GenerationSteps.SELECT_CONTENT);

        const isPartial = optionsStep!.data.id === GenerationMode.PARTIAL;

        generate({
            document: documentStep.data!.id,
            variant: selectContentStep.data!.variantsIds,
            section: selectContentStep.data!.sectionsIds,
            chapter: selectContentStep.data!.chaptersIds,
            expirationTime: timeMap[selectedTime.value],
            propertyName: optionsStep.data?.propertyName,

            brand: brandStep.data?.id,
            region: regionStep.data?.id,
            area: areaStep.data?.id,
            country: countryStep.data?.id,
            propertyType: typeStep.data?.id,

            isPartial,

            watermarkText: watermarkText.trim(),
        });
    }

    function downloadDocument() {
        createDocumentLinkAndClick({
            url: link,
            name: documentStep.data!.name,
        });
    }

    return (
        <div className={cx('page')}>
            <div className="container max-constraint center">
                <div className={cx('content-wrapper')}>

                    <div className={cx('content')}>
                        <div className={cx('title')}>
                            Generate Download Link
                        </div>

                        <div className={cx('select-wrapper')}>
                            <BasicSelect
                                options={expirationTime}
                                title="Expiration time"
                                value={selectedTime}
                                isClearable={false}
                                onChange={(val) => {
                                    setSelectedTime(val);
                                }}
                            />
                        </div>
                        <div className={cx('watermark-input')}>
                            <BasicInput
                                value={watermarkText}
                                label="Watermark text (Optional)"
                                onChange={(val: string) => {
                                    setWatermarkText(val);
                                }}
                            />
                        </div>
                        <div className={cx('link-wrapper')}>
                            <div className={cx('link-title')}>
                                Download Link
                            </div>
                            <textarea
                                ref={linkRef}
                                readOnly
                                value={getDownloadLink()}
                                onClick={() => {
                                    if (link) {
                                        linkRef.current!.select();

                                        const origin = getOrigin();

                                        navigator.clipboard?.writeText(origin + link).then(() => {
                                            notify.info('Copied to clipboard', {
                                                toastId: 'copy-download-link',
                                            });
                                        }).catch();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={cx('button-wrapper')}>
                        <BasicButton
                            title={link ? 'Download PDF' : 'Generate PDF'}
                            isProcessing={isLoading}
                            style={{
                                height: 58,
                                width: '100%',
                                fontSize: 18,
                                backgroundColor: COLOR['app-green'],
                            }}
                            onClick={() => {
                                if (link) {
                                    downloadDocument();
                                } else {
                                    generateDocument();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DownloadDocumentPage;
