import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import invalidateData from '../helpers/invalidateData';
import { chaptersAdminApi } from './chapters.admin-api';
import { sectionsAdminApi } from './sections.admin-api';

type AdminTags = {
    data: {
        id: number;
        name: string;
    } [];
};

type GetAllParams = {
    documentId: number;
};

type TagsApiResponse = {
    id: number;
    name: string;
};

type CreateParams = {
    name: string;
    documentId: number;
};

type UpdateParams = {
    id: number;
    name: string;
    documentId: number;
};

type DeleteParams = {
    id: number;
    documentId: number;
};

function transformGetAllResponse(raw: TagsApiResponse[]) {
    return {
        data: raw.map((tag) => ({
            id: tag.id,
            name: tag.name,
        })),
    };
}

export const tagsAdminApi = createApi({
    reducerPath: 'tags-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAll: builder.query<AdminTags, GetAllParams>({
            query: (params) => ({
                url: '/tags/',
                method: 'GET',
                params: {
                    document_id: params.documentId,
                },
            }),
            transformResponse(raw: TagsApiResponse[]) {
                return transformGetAllResponse(raw);
            },
        }),
        create: builder.mutation<TagsApiResponse, CreateParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    name: params.name,
                    document_id: params.documentId,
                });

                return {
                    url: '/tags/',
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted({ documentId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // ADD TAG TO TAGS LIST
                    dispatch(
                        tagsAdminApi.util.updateQueryData('getAll', { documentId }, (draft) => ({
                            data: [...draft.data, {
                                id: queryData.id,
                                name: queryData.name,
                            }],
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        update: builder.mutation<TagsApiResponse, UpdateParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    name: params.name,
                });

                return {
                    url: `/tags/${params.id}/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
            async onQueryStarted({ id, documentId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        chaptersAdminApi.util.resetApiState(),
                    );

                    dispatch(
                        sectionsAdminApi.util.resetApiState(),
                    );

                    // UPDATE TAG IN TAGS LIST
                    dispatch(
                        tagsAdminApi.util.updateQueryData('getAll', { documentId }, (draft) => ({
                            data: draft.data.map((tag) => {
                                if (tag.id === id) {
                                    return {
                                        ...tag,
                                        name: queryData.name,
                                    };
                                }

                                return tag;
                            }),
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        delete: builder.mutation<void, DeleteParams>({
            query: (params) => ({
                url: `/tags/${params.id}/`,
                method: 'DELETE',
            }),
            async onQueryStarted({ id, documentId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        chaptersAdminApi.util.resetApiState(),
                    );

                    dispatch(
                        sectionsAdminApi.util.resetApiState(),
                    );

                    // DELETE TAG FROM TAGS LIST
                    dispatch(
                        tagsAdminApi.util.updateQueryData('getAll', { documentId }, (draft) => ({
                            data: draft.data.filter((tag) => tag.id !== id),
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
    }),
});

export const {
    useGetAllQuery: useGetAllTags,
    useCreateMutation: useCreateTag,
    useUpdateMutation: useUpdateTag,
    useDeleteMutation: useDeleteTag,
} = tagsAdminApi;
