import React from 'react';
import type { ClearIndicatorProps } from 'react-select';

import { ReactComponent as CloseIcon } from '@ICONS/close-icon.svg';

import classnames from 'classnames/bind';

import styles from './ClearIndicatorBase.module.scss';

const cx = classnames.bind(styles);

function ClearIndicatorBase<T, IsMulti extends boolean>({ clearValue }: ClearIndicatorProps<T, IsMulti>) {
    return (
        <button
            type="button"
            data-button="clear"
            className={cx('clear-indicator-base', 'clear-button')}
            onClick={() => {
                clearValue();
            }}
        >
            <div style={{ stroke: '#4a7a8a', cursor: 'pointer', paddingTop: '5px' }}>
                <CloseIcon />
            </div>
        </button>
    );
}

export default ClearIndicatorBase;
