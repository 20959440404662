type Option = {
    id: number;
    name: string;
};

type Options = {
    brands: Option[],
    regions: Option[],
    areas: Option[],
    countries: Option[],
    propertyTypes: Option[],
};

export function extractSectionsFilters(items: Options[]) {
    const brandsSet = new Set<number>();
    const regionsSet = new Set<number>();
    const areasSet = new Set<number>();
    const countriesSet = new Set<number>();
    const propertyTypesSet = new Set<number>();

    const filters: {
        brands: Option[];
        regions: Option[];
        areas: Option[];
        countries: Option[];
        propertyTypes: Option[];
    } = {
        brands: [],
        regions: [],
        areas: [],
        countries: [],
        propertyTypes: [],
    };

    items.forEach((item: Options) => {
        const {
            brands, regions, areas, countries, propertyTypes,
        } = item;

        brands.forEach((option: Option) => {
            if (!brandsSet.has(option.id)) {
                filters.brands.push(option);

                brandsSet.add(option.id);
            }
        });

        regions.forEach((option: Option) => {
            if (!regionsSet.has(option.id)) {
                filters.regions.push(option);

                regionsSet.add(option.id);
            }
        });

        areas.forEach((brand: Option) => {
            if (!areasSet.has(brand.id)) {
                filters.areas.push(brand);

                areasSet.add(brand.id);
            }
        });

        countries.forEach((option: Option) => {
            if (!countriesSet.has(option.id)) {
                filters.countries.push(option);

                countriesSet.add(option.id);
            }
        });

        propertyTypes.forEach((option: Option) => {
            if (!propertyTypesSet.has(option.id)) {
                filters.propertyTypes.push(option);

                propertyTypesSet.add(option.id);
            }
        });
    });

    return filters;
}
