import React, { useEffect, useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import useAuth from '@HOOKS/useAuth';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import styles from './LoginView.module.scss';

const cx: CX = classnames.bind(styles);

function NotAuthenticated() {
    return (
        <div className={cx('not-authenticated')}>
            Not authenticated
        </div>
    );
}

function AuthLoading() {
    return (
        <div className={cx('loading-wrapper')}>
            <CircularProgressIndicator
                color={COLOR['marriott-primary']}
            />
        </div>
    );
}

function LoginView() {
    const {
        actions,
        state: {
            loginData: {
                isLoading, isError, error,
            },
        },
    } = useAuth();

    const [search] = useSearchParams();

    const email = search.get('email') || '';
    const token = search.get('token') || '';

    useLayoutEffect(() => {
        if (email && token) {
            actions.login({
                email,
                token,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError)?.status === 403) {
                notify.error('Wrong email or token!', {
                    toastId: 'wrong-email-or-token',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'something-wrong',
                });
            }
        }
    }, [isError, error]);

    let child = <NotAuthenticated />;

    if (isLoading) {
        child = <AuthLoading />;
    }

    return (
        <div className={cx('login-view')}>
            {child}
        </div>
    );
}

export default LoginView;
