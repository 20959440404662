const LIMIT = 10;

type AbstractProperty = {
    name: string;
};

export function formatPropertiesList(properties: AbstractProperty[]): string {
    if (properties.length > LIMIT) {
        return `${properties.slice(0, LIMIT).map((p) => p.name).join(', ')} ... (${(properties.length - LIMIT).toString()})`;
    }

    return properties.map((p) => p.name).join(', ');
}
