import React, { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { AdminVendor, useUpdateVendor } from '@SERVICES';
import useGlobalPermissions from '@HOOKS/useGlobalPermissions';

import { VendorFormValues } from '@PAGES/admin/vendors/components/VendorForm/VendorForm.component';
import VendorForm from '@PAGES/admin/vendors/components/VendorForm';

import styles from './VendorData.module.scss';

const cx: CX = classnames.bind(styles);

function VendorData(props: Props) {
    const { vendor } = props;

    const {
        canManageVendors,
    } = useGlobalPermissions();

    const [
        updateVendor,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useUpdateVendor();

    useEffect(() => {
        if (isSuccess) {
            notify.info('Vendor has been updated', {
                toastId: 'update-vendor-options',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-vendor-options-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'update-vendor-options-error',
                });
            }
        }
    }, [isError, error]);

    const onSave = (formValues: VendorFormValues, isHidden: boolean) => {
        if (canManageVendors) {
            updateVendor({
                id: vendor.id,
                name: formValues.name,
                company: formValues.company,
                address: formValues.address,
                email: formValues.email,
                phone: formValues.phone,
                isVisible: !isHidden,
            });
        }
    };

    return (
        <div className={cx('vendor-data')}>
            <VendorForm
                buttonTitle="Save"
                isLoading={isLoading}
                hidden={!vendor.isVisible}
                defaultValues={{
                    name: vendor.name,
                    company: vendor.company,
                    address: vendor.address,
                    email: vendor.email,
                    phone: vendor.phone,
                }}
                onSave={(formValues, isHidden) => onSave(formValues, isHidden)}
            />
        </div>
    );
}

type Props = {
    vendor: AdminVendor,
};

export default VendorData;
