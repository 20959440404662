import React, {
    RefObject, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { SingleValue } from 'react-select';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleDocumentData, useGetAllUsers, useUpdateDocumentPermissions } from '@SERVICES';

import { PermissionItem } from '@PAGES/admin/document/components/DocumentPermissions/DocumentPermissions.component';
import BasicSelect from 'src/view/COMPONENTS/COMMON/inputs/select/inputs/BasicSelect';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';
import ApplySelectButton from '@COMPONENTS/COMMON/buttons/ApplySelectButton';

import styles from './DocumentPermissionsControls.module.scss';

const cx: CX = classnames.bind(styles);

const defaultData = {
    data: [],
};

function DocumentPermissionsControls(props: Props) {
    const {
        documentData,
        selectedPermissions,
        addUser,
    } = props;

    const {
        data: usersData = defaultData, isLoading,
    } = useGetAllUsers();

    const [
        updatePermissions,
        {
            isLoading: isUpdatePermissionsLoading,
            isSuccess: isUpdatePermissionsSuccess,
            isError: isUpdatePermissionsError,
            error: updatePermissionError,
        },
    ] = useUpdateDocumentPermissions();

    const selectWrapperRef: RefObject<HTMLDivElement> = useRef(null);

    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

    useEffect(() => {
        if (isUpdatePermissionsSuccess) {
            notify.info('Permissions has been updated', {
                toastId: 'update-user-document-permissions',
            });
        }
    }, [isUpdatePermissionsSuccess]);

    useEffect(() => {
        if (isUpdatePermissionsError) {
            if ((updatePermissionError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-user-document-permissions-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'update-user-document-permissions-error',
                });
            }
        }
    }, [isUpdatePermissionsError, updatePermissionError]);

    const options = useMemo(() => usersData.data
        .filter((user) => (
            !selectedPermissions.find((u: PermissionItem) => u.id === user.id)
            && documentData.owner.id !== user.id
        ))
        .map((user) => ({
            ...user,
            value: user.id,
            label: user.fullName || user.email,
        })), [selectedPermissions, usersData.data, documentData]);

    const value = useMemo(
        () => options.find((opt) => opt.id === selectedUserId) || null,
        [options, selectedUserId],
    );

    // TODO
    const enableSaveButton = true;

    return (
        <div className={cx('document-permissions-controls')}>
            <div
                ref={selectWrapperRef}
                className={cx('select-wrapper')}
            >
                <BasicSelect
                    title="Add user"
                    placeholder="Select user"
                    isSearchable
                    options={options}
                    value={value}
                    menuPlacement="bottom"
                    isLoading={isLoading}
                    onMenuOpen={() => {
                        if (selectWrapperRef.current) {
                            selectWrapperRef.current.style.zIndex = '6';
                        }
                    }}
                    onMenuClose={() => {
                        if (selectWrapperRef.current) {
                            selectWrapperRef.current.style.zIndex = 'unset';
                        }
                    }}
                    onChange={(val: SingleValue<any>) => {
                        setSelectedUserId(val?.id || null);
                    }}
                />
            </div>
            <div className={cx('buttons-wrapper')}>
                <div className={cx('button')}>
                    <ApplySelectButton
                        tooltip="Add user"
                        locked={false}
                        isProcessing={false}
                        disabled={selectedUserId === null}
                        onClick={() => {
                            if (selectedUserId !== null) {
                                const foundUser = usersData.data.find((u) => u.id === selectedUserId);

                                if (foundUser) {
                                    addUser(foundUser);

                                    setSelectedUserId(null);
                                }
                            }
                        }}
                    />
                </div>
                <div className={cx('button')}>
                    <BasicButton
                        tooltip="Save permissions"
                        locked={false}
                        isProcessing={isUpdatePermissionsLoading}
                        title="Save"
                        style={{
                            height: 36,
                            width: 100,
                            fontSize: 12,
                            backgroundColor: enableSaveButton ? COLOR['app-green'] : COLOR['marriott-light-grey'],
                        }}
                        onClick={() => {
                            updatePermissions({
                                id: documentData.id,
                                usersPermissions: selectedPermissions.map((user: PermissionItem) => ({
                                    userId: user.id,
                                    permissions: Array.from(user.permissions),
                                })).filter((user: any) => user.permissions.length > 0),
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

DocumentPermissionsControls.propTypes = {
    addUser: PropTypes.func.isRequired,
};

type Props = InferProps<typeof DocumentPermissionsControls.propTypes> & {
    documentData: AdminSingleDocumentData,
    selectedPermissions: PermissionItem[],
};

export default DocumentPermissionsControls;
