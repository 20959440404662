import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import { formatVersion } from '../helpers/formatVersion';
import { extractSectionsFilters } from '../helpers/extractSectionsFilters';

type AdminPreviewDocumentApiResponse = {
    data: {
        id: number;
        name: string;
        cover: string | null;
        chapters: {
            id: number;
            name: string;
            order: number;
            image: string | null;
            introduction_text: {
                body: string;
                is_published: boolean;
                version: {
                    id: number;
                    description: string;
                    body: string;
                    created_at: string;
                    author: {
                        id: number;
                        full_name: string;
                        email: string;
                    };
                } | null;
            };
            sections: {
                id: number;
                name: string;
                is_landscape: boolean;
                order: number;
                base_text: {
                    body: string;
                    is_published: boolean;
                    version: {
                        id: number;
                        description: string;
                        body: string;
                        created_at: string;
                        author: {
                            id: number;
                            full_name: string;
                            email: string;
                        };
                    } | null;
                };
                variants: {
                    id: number;
                    name: string;
                    body: string;
                    is_default: boolean;
                    is_published: boolean;
                    version: {
                        id: number;
                        description: string;
                        body: string;
                        created_at: string;
                        author: {
                            id: number;
                            full_name: string;
                            email: string;
                        };
                    } | null;
                    brands: {
                        id: number;
                        name: string;
                    }[];
                    regions: {
                        id: number;
                        name: string;
                    }[];
                    areas: {
                        id: number;
                        name: string;
                    }[];
                    countries: {
                        id: number;
                        name: string;
                    }[];
                    property_types: {
                        id: number;
                        name: string;
                    }[];
                }[];
            }[];
        }[];
    };
};

type AdminPreviewChapterApiResponse = {
    data: {
        id: number;
        name: string;
        order: number;
        image: string | null;
        introduction_text: {
            body: string;
            is_published: boolean;
            version: {
                id: number;
                description: string;
                body: string;
                created_at: string;
                author: {
                    id: number;

                    full_name: string;

                    email: string;
                };
            } | null;
        };
        sections: {
            id: number;
            name: string;
            is_landscape: boolean;
            order: number;
            base_text: {
                body: string;
                is_published: boolean;
                version: {
                    id: number;
                    description: string;
                    body: string;
                    created_at: string;
                    author: {
                        id: number;
                        full_name: string;
                        email: string;
                    };
                } | null;
            };
            variants: {
                id: number;
                name: string;
                body: string;
                is_default: boolean;
                is_published: boolean;
                version: {
                    id: number;
                    description: string;
                    body: string;
                    created_at: string;
                    author: {
                        id: number;
                        full_name: string;
                        email: string;
                    };
                } | null;
                brands: {
                    id: number;
                    name: string;
                }[];
                regions: {
                    id: number;
                    name: string;
                }[];
                areas: {
                    id: number;
                    name: string;
                }[];
                countries: {
                    id: number;
                    name: string;
                }[];
                property_types: {
                    id: number;
                    name: string;
                }[];
            }[];
        }[];
    };
};

export type AdminPreviewDocument = {
    data: AdminPreviewDocumentData;
    meta: AdminPreviewDocumentMeta;
};

export type AdminPreviewDocumentData = {
    id: number;
    name: string;
    cover: string | null;
    chapters: AdminPreviewDocumentChapter[];
};

export type AdminPreviewDocumentMeta = {
    filters: {
        brands: AdminPreviewVariantProperty[];
        regions: AdminPreviewVariantProperty[];
        areas: AdminPreviewVariantProperty[];
        countries: AdminPreviewVariantProperty[];
        propertyTypes: AdminPreviewVariantProperty[];
    }
};

export type AdminPreviewDocumentChapter = {
    id: number;
    name: string;
    order: number;
    image: string | null;
    introductionText: AdminPreviewIntroductionText;
    sections: AdminPreviewDocumentChapterSection[];
};

export type AdminPreviewDocumentChapterSection = {
    id: number;
    name: string;
    isLandscape: boolean;
    order: number;
    baseText: AdminPreviewBaseText;
    variants: AdminPreviewDocumentChapterSectionVariant[];
};

export type AdminPreviewDocumentChapterSectionVariant = {
    id: number;
    name: string;
    body: string;
    isDefault: boolean;
    isPublished: boolean;
    latestVersion: AdminPreviewVersion;
    brands: AdminPreviewVariantProperty[];
    regions: AdminPreviewVariantProperty[];
    areas: AdminPreviewVariantProperty[];
    countries: AdminPreviewVariantProperty[];
    propertyTypes: AdminPreviewVariantProperty[];
};

export type AdminPreviewDocumentVersion = {
    id: number;
    description: string;
    body: string;
    createdAt: string;
    author: {
        id: number;
        fullName: string;
        email: string;
    };
} | null;

// =====================================================================================================================

export type AdminPreviewChapter = {
    data: AdminPreviewChapterData;
    meta: AdminPreviewChapterMeta;
};

export type AdminPreviewChapterData = {
    id: number;
    name: string;
    order: number;
    image: string | null;
    introductionText: AdminPreviewIntroductionText;
    sections: AdminPreviewChapterSection[];
};

export type AdminPreviewChapterMeta = {
    filters: {
        brands: AdminPreviewVariantProperty[];
        regions: AdminPreviewVariantProperty[];
        areas: AdminPreviewVariantProperty[];
        countries: AdminPreviewVariantProperty[];
        propertyTypes: AdminPreviewVariantProperty[];
    }
};

export type AdminPreviewChapterSection = {
    id: number;
    name: string;
    isLandscape: boolean;
    order: number;
    baseText: AdminPreviewBaseText;
    variants: AdminPreviewChapterSectionVariant[];
};

export type AdminPreviewChapterSectionVariant = {
    id: number;
    name: string;
    body: string;
    isDefault: boolean;
    isPublished: boolean;
    latestVersion: AdminPreviewVersion;
    brands: AdminPreviewVariantProperty[];
    regions: AdminPreviewVariantProperty[];
    areas: AdminPreviewVariantProperty[];
    countries: AdminPreviewVariantProperty[];
    propertyTypes: AdminPreviewVariantProperty[];
};

// =====================================================================================================================

export type AdminPreviewIntroductionText = {
    body: string;
    isPublished: boolean;
    latestVersion: AdminPreviewVersion;
};

export type AdminPreviewBaseText = {
    body: string;
    isPublished: boolean;
    latestVersion: AdminPreviewDocumentVersion;
};

export type AdminPreviewVersion = {
    id: number;
    description: string;
    body: string;
    createdAt: string;
    author: {
        id: number;
        fullName: string;
        email: string;
    };
} | null;

export type AdminPreviewVariantProperty = {
    id: number;
    name: string;
};

function transformChapterPreviewResponse(raw: AdminPreviewChapterApiResponse): AdminPreviewChapter {
    type Section = AdminPreviewChapterApiResponse['data']['sections'][number];

    const filters: any = extractSectionsFilters(raw.data.sections.reduce((acc: any, section: Section) => {
        const { variants } = section;

        type Variant = Section['variants'][number];

        return [...acc, ...variants.reduce((innerAcc: any, variant: Variant) => [...innerAcc, {
            brands: variant.brands,
            regions: variant.regions,
            areas: variant.areas,
            countries: variant.countries,
            propertyTypes: variant.property_types,
        }], [])];
    }, []));

    const data: AdminPreviewChapterData = {
        id: raw.data.id,
        name: raw.data.name,
        image: raw.data.image,
        order: raw.data.order,
        introductionText: {
            body: raw.data.introduction_text.body || '',
            isPublished: raw.data.introduction_text.is_published,
            latestVersion: raw.data.introduction_text.version ? formatVersion(raw.data.introduction_text.version) : null,
        },
        sections: raw.data.sections.map((section) => ({
            id: section.id,
            name: section.name,
            isLandscape: section.is_landscape,
            order: section.order,
            baseText: {
                body: section.base_text.body || '',
                isPublished: section.base_text.is_published,
                latestVersion: section.base_text.version ? formatVersion(section.base_text.version) : null,
            },
            variants: section.variants.map((variant) => ({
                id: variant.id,
                name: variant.name,
                body: variant.body || '',
                isDefault: variant.is_default,
                isPublished: variant.is_published,
                latestVersion: variant.version ? formatVersion(variant.version) : null,
                brands: variant.brands,
                regions: variant.regions,
                areas: variant.areas,
                countries: variant.countries,
                propertyTypes: variant.property_types,
            })),
        })),
    };

    return {
        data,
        meta: {
            filters,
        },
    };
}

function transformDocumentPreviewResponse(raw: AdminPreviewDocumentApiResponse): AdminPreviewDocument {
    type ApiChapter = AdminPreviewDocumentApiResponse['data']['chapters'][number];
    type Acc = AdminPreviewChapterMeta['filters'][];

    const filters = extractSectionsFilters(raw.data.chapters.reduce((chapterAcc: Acc, chapter: ApiChapter) => {
        const { sections } = chapter;

        type ApiSection = ApiChapter['sections'][number];

        return [...chapterAcc, ...sections.reduce((sectionsAcc: Acc, section: ApiSection) => {
            const { variants } = section;

            type ApiVariant = ApiSection['variants'][number];

            return [...sectionsAcc, ...variants.reduce((variantsAcc: Acc, variant: ApiVariant) => [...variantsAcc, {
                brands: variant.brands,
                regions: variant.regions,
                areas: variant.areas,
                countries: variant.countries,
                propertyTypes: variant.property_types,
            }], [])];
        }, [])];
    }, []));

    const data: AdminPreviewDocumentData = {
        id: raw.data.id,
        name: raw.data.name,
        cover: raw.data.cover,
        chapters: raw.data.chapters.map((chapter) => ({
            id: chapter.id,
            name: chapter.name,
            image: chapter.image,
            order: chapter.order,
            introductionText: {
                body: chapter.introduction_text.body || '',
                isPublished: chapter.introduction_text.is_published,
                latestVersion: chapter.introduction_text.version ? formatVersion(chapter.introduction_text.version) : null,
            },
            sections: chapter.sections.map((section) => ({
                id: section.id,
                name: section.name,
                isLandscape: section.is_landscape,
                order: section.order,
                baseText: {
                    body: section.base_text.body || '',
                    isPublished: section.base_text.is_published,
                    latestVersion: section.base_text.version ? formatVersion(section.base_text.version) : null,
                },
                variants: section.variants.map((variant) => ({
                    id: variant.id,
                    name: variant.name,
                    body: variant.body || '',
                    isDefault: variant.is_default,
                    isPublished: variant.is_published,
                    latestVersion: variant.version ? formatVersion(variant.version) : null,
                    brands: variant.brands,
                    regions: variant.regions,
                    areas: variant.areas,
                    countries: variant.countries,
                    propertyTypes: variant.property_types,
                })),
            })),
        })),
    };

    return {
        data,
        meta: {
            filters,
        },
    };
}

export const previewAdminApi = createApi({
    reducerPath: 'preview-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        documentPreview: builder.query<AdminPreviewDocument, number>({
            query: (id: number) => `/documents/${id}/preview/`,
            transformResponse(raw: AdminPreviewDocumentApiResponse) {
                return transformDocumentPreviewResponse(raw);
            },
        }),
        chapterPreview: builder.query<AdminPreviewChapter, number>({
            query: (id: number) => `/chapters/${id}/preview/`,
            transformResponse(raw: AdminPreviewChapterApiResponse) {
                return transformChapterPreviewResponse(raw);
            },
        }),
    }),
});

export const {
    useDocumentPreviewQuery: useDocumentPreview,
    useChapterPreviewQuery: useChapterPreview,
} = previewAdminApi;
