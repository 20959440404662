import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';

import invalidateData from '../helpers/invalidateData';
import { documentsAdminApi } from './documents.admin-api';
import { sectionsAdminApi } from './sections.admin-api';
import { chaptersAdminApi } from './chapters.admin-api';
import { unpublishedAdminApi } from './unpublished.admin-api';
import { formatVersion } from '../helpers/formatVersion';

// ALL

type PublishAllApiResponse = {
    chapters: {
        id: number;
        version: {
            id: number;
            description: string | null;
            body: string;
            created_at: string;
            author: {
                id: number;
                full_name: string;
                email: string;
            },
        }
    }[];
    sections: {
        id: number;
        version: {
            id: number;
            description: string | null;
            body: string;
            created_at: string;
            author: {
                id: number;
                full_name: string;
                email: string;
            },
        }
    }[];
    variants: {
        id: number;
        version: {
            id: number;
            description: string | null;
            body: string;
            created_at: string;
            author: {
                id: number;
                full_name: string;
                email: string;
            },
        }
    }[];
};

type PublishAllParams = {
    type: 'document' | 'chapter' | 'section',
    documentId: number;
    data: {
        description: string | null;
        userId: number;
        chapters: {
            id: number;
            documentId: number;
            body: string;
        }[];
        sections: {
            id: number;
            chapterId: number;
            documentId: number;
            body: string;
        }[];
        variants: {
            id: number;
            sectionId: number;
            chapterId: number;
            documentId: number;
            body: string;
        }[];
    }
};

// CHAPTER

type PublishChapterIntroductionTextApiResponse = {
    id: number;
    description: string | null;
    body: string;
    created_at: string;
    author: {
        id: number;
        full_name: string;
        email: string;
    },
};

type PublishChapterIntroductionTextParams = {
    id: number;
    body: string;
    description: string | null;
    userId: number;
    documentId: number;
};

// SECTION

type PublishSectionBaseTextApiResponse = {
    id: number;
    description: string | null;
    body: string;
    created_at: string;
    author: {
        id: number;
        full_name: string;
        email: string;
    },
};

type PublishSectionBaseTextParams = {
    id: number;
    body: string;
    description: string | null;
    userId: number;
    documentId: number;
    chapterId: number;
};

// VARIANT

type PublishVariantApiResponse = {
    id: number;
    description: string | null;
    body: string;
    created_at: string;
    author: {
        id: number;
        full_name: string;
        email: string;
    },
};

type PublishVariantParams = {
    id: number;
    body: string;
    description: string | null;
    userId: number;
    documentId: number;
    chapterId: number;
    sectionId: number;
};

export const publishAdminApi = createApi({
    reducerPath: 'publish-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        publishAll: builder.mutation<PublishAllApiResponse, PublishAllParams>({
            query: (params) => {
                const {
                    documentId,
                    data: {
                        description,
                        userId,
                        chapters,
                        sections,
                        variants,
                    },
                } = params;

                const formData = new FormData();

                formData.append('document', documentId.toString());
                formData.append('user_id', userId.toString());

                if (description) {
                    formData.append('description', description);
                }

                formData.append('chapters', chapters.map((chapter) => chapter.id).join(','));
                formData.append('sections', sections.map((section) => section.id).join(','));
                formData.append('variants', variants.map((variant) => variant.id).join(','));

                return {
                    url: '/bulk-publish/',
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const { type, data: paramsData } = args;

                try {
                    const { data: queryData } = await queryFulfilled;

                    const {
                        chapters: publishedChapters,
                        sections: publishedSections,
                        variants: publishedVariants,
                    } = queryData;

                    const chaptersIds = publishedChapters.map((c) => c.id);
                    const sectionsIds = publishedSections.map((s) => s.id);
                    const variantsIds = publishedVariants.map((v) => v.id);

                    const sectionId = paramsData.sections[0]?.id || paramsData.variants[0]?.sectionId;
                    const chapterId = paramsData.chapters[0]?.id || paramsData.sections[0]?.chapterId || paramsData.variants[0]?.chapterId;
                    const documentId = paramsData.chapters[0]?.documentId || paramsData.sections[0]?.documentId || paramsData.variants[0]?.documentId;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    if (type === 'document') {
                        dispatch(chaptersAdminApi.util.resetApiState());
                        dispatch(sectionsAdminApi.util.resetApiState());

                        if (documentId) {
                            // ADD NEW VERSIONS TO UNPUBLISHED DOCUMENT
                            dispatch(
                                unpublishedAdminApi.util.updateQueryData('getUnpublishedDocument', documentId, (draft) => ({
                                    ...draft,
                                    chapters: draft.chapters
                                        .map((chapter) => {
                                            // UPDATE CHAPTER INTRODUCTION TEXT
                                            if (chaptersIds.includes(chapter.id)) {
                                                return {
                                                    ...chapter,
                                                    introductionText: {
                                                        ...chapter.introductionText,
                                                        isPublished: true,
                                                    },
                                                };
                                            }

                                            return chapter;
                                        })
                                        .map((chapter) => ({
                                            ...chapter,
                                            // UPDATE SECTION BASE TEXT
                                            sections: chapter.sections.map((section) => {
                                                if (sectionsIds.includes(section.id)) {
                                                    return {
                                                        ...section,
                                                        baseText: {
                                                            ...section.baseText,
                                                            isPublished: true,
                                                        },
                                                    };
                                                }

                                                return section;
                                            }).map((section) => ({
                                                ...section,
                                                // UPDATE VARIANTS
                                                variants: section.variants.map((variant) => {
                                                    if (variantsIds.includes(variant.id)) {
                                                        return {
                                                            ...variant,
                                                            isPublished: true,
                                                        };
                                                    }

                                                    return variant;
                                                }),
                                            })),
                                        })),
                                })),
                            );

                            // ADD NEW VERSIONS TO SINGLE DOCUMENT
                            dispatch(
                                documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                                    data: {
                                        ...draft.data,
                                        chapters: draft.data.chapters
                                            .map((chapter) => {
                                                // UPDATE CHAPTER INTRODUCTION TEXT
                                                if (chaptersIds.includes(chapter.id)) {
                                                    return {
                                                        ...chapter,
                                                        introductionText: {
                                                            isPublished: true,
                                                        },
                                                    };
                                                }

                                                return chapter;
                                            })
                                            .map((chapter) => ({
                                                ...chapter,
                                                // UPDATE SECTION BASE TEXT
                                                sections: chapter.sections.map((section) => {
                                                    if (sectionsIds.includes(section.id)) {
                                                        return {
                                                            ...section,
                                                            baseText: {
                                                                isPublished: true,
                                                            },
                                                        };
                                                    }

                                                    return section;
                                                }).map((section) => ({
                                                    ...section,
                                                    // UPDATE VARIANTS
                                                    variants: section.variants.map((variant) => {
                                                        if (variantsIds.includes(variant.id)) {
                                                            return {
                                                                ...variant,
                                                                isPublished: true,
                                                            };
                                                        }

                                                        return variant;
                                                    }),
                                                })),
                                            })),
                                    },
                                    meta: draft.meta,
                                })),
                            );
                        }
                    }

                    if (type === 'chapter') {
                        dispatch(sectionsAdminApi.util.resetApiState());
                        dispatch(unpublishedAdminApi.util.resetApiState());

                        if (documentId && chapterId) {
                            // ADD NEW VERSIONS TO SINGLE DOCUMENT
                            dispatch(
                                documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                                    data: {
                                        ...draft.data,
                                        chapters: draft.data.chapters
                                            .map((chapter) => {
                                                // UPDATE CHAPTER INTRODUCTION TEXT
                                                if (chaptersIds.includes(chapter.id)) {
                                                    return {
                                                        ...chapter,
                                                        introductionText: {
                                                            isPublished: true,
                                                        },
                                                    };
                                                }

                                                return chapter;
                                            })
                                            .map((chapter) => {
                                                if (chapter.id === chapterId) {
                                                    return {
                                                        ...chapter,
                                                        // UPDATE SECTION BASE TEXT
                                                        sections: chapter.sections.map((section) => {
                                                            if (sectionsIds.includes(section.id)) {
                                                                return {
                                                                    ...section,
                                                                    baseText: {
                                                                        isPublished: true,
                                                                    },
                                                                };
                                                            }

                                                            return section;
                                                        }).map((section) => ({
                                                            ...section,
                                                            // UPDATE VARIANTS
                                                            variants: section.variants.map((variant) => {
                                                                if (variantsIds.includes(variant.id)) {
                                                                    return {
                                                                        ...variant,
                                                                        isPublished: true,
                                                                    };
                                                                }

                                                                return variant;
                                                            }),
                                                        })),
                                                    };
                                                }

                                                return chapter;
                                            }),
                                    },
                                    meta: draft.meta,
                                })),
                            );
                        }

                        if (chapterId) {
                            // ADD NEW VERSIONS TO SINGLE CHAPTER
                            dispatch(
                                chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => {
                                    const publishedChapter = publishedChapters.find((s) => s.id === chapterId);

                                    let newChapterVersion;

                                    if (publishedChapter) {
                                        const { version } = publishedChapter;

                                        newChapterVersion = formatVersion(version);
                                    }

                                    return ({
                                        data: {
                                            ...draft.data,
                                            introductionText: newChapterVersion ? {
                                                body: newChapterVersion.body,
                                                isPublished: true,
                                                versions: [...draft.data.introductionText.versions, newChapterVersion],
                                            } : draft.data.introductionText,
                                            // UPDATE SECTION BASE TEXT
                                            sections: draft.data.sections.map((section) => {
                                                if (sectionsIds.includes(section.id)) {
                                                    const publishedSection = publishedSections.find((s) => s.id === section.id)!;

                                                    const { version } = publishedSection;

                                                    const newSectionVersion = formatVersion(version);

                                                    return {
                                                        ...section,
                                                        baseText: {
                                                            body: newSectionVersion.body,
                                                            isPublished: true,
                                                            latestVersion: newSectionVersion,
                                                        },
                                                    };
                                                }

                                                return section;
                                            }).map((section) => ({
                                                ...section,
                                                // UPDATE VARIANTS
                                                variants: section.variants.map((variant) => {
                                                    if (variantsIds.includes(variant.id)) {
                                                        const publishedVariant = publishedVariants.find((v) => v.id === variant.id)!;

                                                        const { version } = publishedVariant;

                                                        const newVariantVersion = formatVersion(version);

                                                        return {
                                                            ...variant,
                                                            body: newVariantVersion.body,
                                                            isPublished: true,
                                                            latestVersion: newVariantVersion,
                                                        };
                                                    }

                                                    return variant;
                                                }),
                                            })),
                                        },
                                        meta: draft.meta,
                                    });
                                }),
                            );
                        }
                    }

                    if (type === 'section') {
                        dispatch(unpublishedAdminApi.util.resetApiState());

                        if (documentId && chapterId) {
                            // ADD NEW VERSIONS TO SINGLE DOCUMENT
                            dispatch(
                                documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                                    data: {
                                        ...draft.data,
                                        chapters: draft.data.chapters.map((chapter) => {
                                            if (chapter.id === chapterId) {
                                                return {
                                                    ...chapter,
                                                    // UPDATE SECTION BASE TEXT
                                                    sections: chapter.sections.map((section) => {
                                                        if (sectionsIds.includes(section.id)) {
                                                            return {
                                                                ...section,
                                                                baseText: {
                                                                    isPublished: true,
                                                                },
                                                            };
                                                        }

                                                        return section;
                                                    }).map((section) => ({
                                                        ...section,
                                                        // UPDATE VARIANTS
                                                        variants: section.variants.map((variant) => {
                                                            if (variantsIds.includes(variant.id)) {
                                                                return {
                                                                    ...variant,
                                                                    isPublished: true,
                                                                };
                                                            }

                                                            return variant;
                                                        }),
                                                    })),
                                                };
                                            }

                                            return chapter;
                                        }),
                                    },
                                    meta: draft.meta,
                                })),
                            );
                        }

                        if (chapterId && sectionId) {
                            // ADD NEW VERSIONS TO SINGLE CHAPTER
                            dispatch(
                                chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                                    data: {
                                        ...draft.data,
                                        // UPDATE BASE TEXT
                                        sections: draft.data.sections.map((section) => {
                                            if (sectionsIds.includes(section.id)) {
                                                const publishedSection = publishedSections.find((s) => s.id === sectionId)!;

                                                const { version } = publishedSection;

                                                const newSectionVersion = formatVersion(version);

                                                return {
                                                    ...section,
                                                    baseText: {
                                                        body: newSectionVersion.body,
                                                        isPublished: true,
                                                        latestVersion: newSectionVersion,
                                                    },
                                                };
                                            }

                                            return section;
                                        }).map((section) => ({
                                            ...section,
                                            // UPDATE VARIANTS
                                            variants: section.variants.map((variant) => {
                                                if (variantsIds.includes(variant.id)) {
                                                    const publishedVariant = publishedVariants.find((v) => v.id === variant.id)!;

                                                    const { version } = publishedVariant;

                                                    const newVariantVersion = formatVersion(version);

                                                    return {
                                                        ...variant,
                                                        body: newVariantVersion.body,
                                                        isPublished: true,
                                                        latestVersion: newVariantVersion,
                                                    };
                                                }

                                                return variant;
                                            }),
                                        })),
                                    },
                                    meta: draft.meta,
                                })),
                            );
                        }

                        if (sectionId) {
                            // ADD NEW VERSIONS TO SINGLE SECTION
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => {
                                    const publishedSection = publishedSections.find((s) => s.id === sectionId);

                                    let newSectionVersion;

                                    if (publishedSection) {
                                        const { version } = publishedSection;

                                        newSectionVersion = formatVersion(version);
                                    }

                                    return ({
                                        data: {
                                            ...draft.data,
                                            baseText: newSectionVersion ? {
                                                body: newSectionVersion.body,
                                                isPublished: true,
                                                versions: [...draft.data.baseText.versions, newSectionVersion],
                                            } : draft.data.baseText,
                                            variants: draft.data.variants.map((variant) => {
                                                if (variantsIds.includes(variant.id)) {
                                                    const publishedVariant = publishedVariants.find((v) => v.id === variant.id)!;

                                                    const { version } = publishedVariant;

                                                    const newVariantVersion = formatVersion(version);

                                                    return {
                                                        ...variant,
                                                        body: newVariantVersion.body,
                                                        isPublished: true,
                                                        versions: [...variant.versions, newVariantVersion],
                                                    };
                                                }

                                                return variant;
                                            }),
                                        },
                                        meta: draft.meta,
                                    });
                                }),
                            );

                            // ADD NEW VERSIONS TO SECTION HISTORY
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('history', sectionId, (draft) => {
                                    const publishedSection = publishedSections.find((s) => s.id === sectionId);

                                    let newSectionVersion;

                                    if (publishedSection) {
                                        const { version } = publishedSection;

                                        newSectionVersion = formatVersion(version);
                                    }

                                    return ({
                                        data: {
                                            ...draft.data,
                                            baseText: newSectionVersion ? {
                                                body: newSectionVersion.body,
                                                isPublished: true,
                                                versions: [...draft.data.baseText.versions, newSectionVersion],
                                            } : draft.data.baseText,
                                            variants: draft.data.variants.map((variant) => {
                                                if (variantsIds.includes(variant.id)) {
                                                    const publishedVariant = publishedVariants.find((v) => v.id === variant.id)!;

                                                    const { version } = publishedVariant;

                                                    const newVariantVersion = formatVersion(version);

                                                    return {
                                                        ...variant,
                                                        isPublished: true,
                                                        versions: [...variant.versions, newVariantVersion],
                                                    };
                                                }

                                                return variant;
                                            }),
                                        },
                                    });
                                }),
                            );
                        }
                    }
                } catch (e) {
                    //
                }
            },
        }),
        publishChapterIntroductionText: builder.mutation<PublishChapterIntroductionTextApiResponse, PublishChapterIntroductionTextParams>({
            query: (params) => {
                const formData = new FormData();

                formData.append('body', params.body);
                formData.append('user_id', params.userId.toString());

                if (params.description) {
                    formData.append('description', params.description);
                }

                return {
                    url: `/chapters/${params.id}/publish/`,
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const {
                    id,
                    documentId,
                } = args;

                try {
                    const { data: queryData } = await queryFulfilled;

                    const newVersion = formatVersion(queryData);

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(unpublishedAdminApi.util.resetApiState());

                    // ADD NEW INTRODUCTION TEXT VERSION TO SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === id) {
                                        return {
                                            ...chapter,
                                            introductionText: {
                                                isPublished: true,
                                            },
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // ADD NEW INTRODUCTION TEXT VERSION TO SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                introductionText: {
                                    body: queryData.body,
                                    isPublished: true,
                                    versions: [...draft.data.introductionText.versions, newVersion],
                                },
                            },
                            meta: draft.meta,
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        publishSectionBaseText: builder.mutation<PublishSectionBaseTextApiResponse, PublishSectionBaseTextParams>({
            query: (params) => {
                const formData = new FormData();

                formData.append('body', params.body);
                formData.append('user_id', params.userId.toString());

                if (params.description) {
                    formData.append('description', params.description);
                }

                return {
                    url: `/sections/${params.id}/publish/`,
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const {
                    id,
                    documentId,
                    chapterId,
                } = args;

                try {
                    const { data: queryData } = await queryFulfilled;

                    const newVersion = formatVersion(queryData);

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(unpublishedAdminApi.util.resetApiState());

                    // ADD NEW BASE TEXT VERSION TO SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === chapterId) {
                                        return {
                                            ...chapter,
                                            sections: chapter.sections.map((section) => {
                                                if (section.id === id) {
                                                    return {
                                                        ...section,
                                                        baseText: {
                                                            isPublished: true,
                                                        },
                                                    };
                                                }

                                                return section;
                                            }),
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // ADD NEW BASE TEXT VERSION TO SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: draft.data.sections.map((section) => {
                                    if (section.id === id) {
                                        return {
                                            ...section,
                                            baseText: {
                                                body: queryData.body,
                                                isPublished: true,
                                                latestVersion: newVersion,
                                            },
                                        };
                                    }

                                    return section;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // ADD NEW BASE TEXT VERSION TO SINGLE SECTION
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                baseText: {
                                    body: queryData.body,
                                    isPublished: true,
                                    versions: [...draft.data.baseText.versions, newVersion],
                                },
                            },
                            meta: draft.meta,
                        })),
                    );

                    // ADD NEW BASE TEXT VERSION TO SECTION VERSION HISTORY
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('history', id, (draft) => ({
                            data: {
                                ...draft.data,
                                baseText: {
                                    isPublished: true,
                                    versions: [...draft.data.baseText.versions, newVersion],
                                },
                            },
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        publishVariant: builder.mutation<PublishVariantApiResponse, PublishVariantParams>({
            query: (params) => {
                const formData = new FormData();

                formData.append('body', params.body);
                formData.append('user_id', params.userId.toString());

                if (params.description) {
                    formData.append('description', params.description);
                }

                return {
                    url: `/variants/${params.id}/publish/`,
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const {
                    id,
                    documentId,
                    chapterId,
                    sectionId,
                } = args;

                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    const newVersion = formatVersion(queryData);

                    // ADD NEW VERSION TO SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === chapterId) {
                                        return {
                                            ...chapter,
                                            sections: chapter.sections.map((section) => {
                                                if (section.id === sectionId) {
                                                    return {
                                                        ...section,
                                                        variants: section.variants.map((variant) => {
                                                            if (variant.id === id) {
                                                                return {
                                                                    ...variant,
                                                                    isPublished: true,
                                                                };
                                                            }

                                                            return variant;
                                                        }),
                                                    };
                                                }

                                                return section;
                                            }),
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // ADD NEW VERSION TO SINGLE SECTION
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: draft.data.variants.map((variant) => {
                                    if (variant.id === id) {
                                        return {
                                            ...variant,
                                            body: queryData.body,
                                            isPublished: true,
                                            versions: [...variant.versions, newVersion],
                                        };
                                    }

                                    return variant;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // ADD NEW VERSION TO SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: draft.data.sections.map((section) => {
                                    if (section.id === sectionId) {
                                        return {
                                            ...section,
                                            variants: section.variants.map((variant) => {
                                                if (variant.id === id) {
                                                    return {
                                                        ...variant,
                                                        isPublished: true,
                                                        body: queryData.body,
                                                        latestVersion: newVersion,
                                                    };
                                                }

                                                return variant;
                                            }),
                                        };
                                    }

                                    return section;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // ADD NEW VERSION TO SECTION VERSION HISTORY
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('history', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: draft.data.variants.map((variant) => {
                                    if (variant.id === id) {
                                        return {
                                            ...variant,
                                            isPublished: true,
                                            versions: [...variant.versions, newVersion],
                                        };
                                    }

                                    return variant;
                                }),
                            },
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
    }),
});

export const {
    usePublishAllMutation: usePublishAll,
    usePublishChapterIntroductionTextMutation: usePublishChapterIntroductionText,
    usePublishSectionBaseTextMutation: usePublishSectionBaseText,
    usePublishVariantMutation: usePublishVariant,
} = publishAdminApi;
