import React, { useId } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Select, { MenuPlacement, Options, SingleValue } from 'react-select';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import { selectBaseStyles } from '@COMPONENTS/COMMON/inputs/select/config';
import NoOptionsMessageBase from '@COMPONENTS/COMMON/inputs/select/base/NoOptionsMessageBase';
import ClearIndicatorBase from '@COMPONENTS/COMMON/inputs/select/base/ClearIndicatorBase';
import DropdownIndicatorBase from '@COMPONENTS/COMMON/inputs/select/base/DropdownIndicatorBase';
import styles from './BasicSelect.module.scss';

const cx: CX = classnames.bind(styles);

function BasicSelect(props: Props) {
    const {
        title,
        placeholder,
        options,
        value,
        isClearable,
        isSearchable,
        isDisabled,
        isLoading,
        menuPlacement,
        onChange,
        onMenuOpen,
        onMenuClose,
    } = props;

    const inputId = useId();

    function handleChange(val: any) {
        onChange(val);
    }

    return (
        <div className={cx('basic-select')}>
            <label htmlFor={inputId}>
                <div className={cx('title')}>
                    {title}
                </div>
                {
                    isLoading
                    && (
                        <div className={cx('loading-wrapper')}>
                            <CircularProgressIndicator
                                size={12}
                                thickness={2}
                                color={COLOR['marriott-light-grey']}
                            />
                        </div>
                    )
                }
            </label>
            <Select
                inputId={inputId}
                options={options}
                isClearable={isClearable}
                isSearchable={isSearchable}
                isDisabled={isDisabled}
                placeholder={placeholder}
                menuPlacement={menuPlacement as MenuPlacement}
                menuShouldScrollIntoView={false}
                value={value}
                onChange={(val: any) => handleChange(val)}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                unstyled
                components={{
                    IndicatorSeparator: () => null,
                    NoOptionsMessage: NoOptionsMessageBase,
                    ClearIndicator: ClearIndicatorBase,
                    DropdownIndicator: DropdownIndicatorBase,
                }}
                styles={selectBaseStyles}
                classNames={{
                    menuList: () => cx('menu-list'),
                }}
            />
        </div>
    );
}

BasicSelect.defaultProps = {
    title: '',
    placeholder: 'Select...',
    isClearable: true,
    isSearchable: false,
    isDisabled: false,
    isLoading: false,
    menuPlacement: 'auto',
    onMenuOpen: () => {
    },
    onMenuClose: () => {
    },
};

BasicSelect.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onMenuOpen: PropTypes.func,
    onMenuClose: PropTypes.func,
    menuPlacement: PropTypes.oneOf(['auto', 'bottom', 'top']),
};

type Props = InferProps<typeof BasicSelect.propTypes> & typeof BasicSelect.defaultProps & {
    options: Options<any>,
    value: SingleValue<any>
};

export default BasicSelect;
