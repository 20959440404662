import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import { formatVersion } from '../helpers/formatVersion';

type GetUnpublishedDocumentApiResponse = {
    id: number;
    name: string;
    chapters: {
        id: number;
        name: string;
        introduction_text: {
            body: string;
            is_published: boolean;
            version: {
                id: number;
                description: string;
                body: string;
                created_at: string;
                author: {
                    id: number;
                    full_name: string;
                    email: string;
                };
            } | null;
        };
        sections: {
            id: number;
            name: string;
            is_landscape: boolean;
            base_text: {
                body: string;
                is_published: boolean;
                version: {
                    id: number;
                    description: string;
                    body: string;
                    created_at: string;
                    author: {
                        id: number;
                        full_name: string;
                        email: string;
                    };
                } | null;
            };
            variants: {
                id: number;
                name: string;
                body: string;
                is_default: boolean;
                is_published: boolean;
                version: {
                    id: number;
                    description: string;
                    body: string;
                    created_at: string;
                    author: {
                        id: number;
                        full_name: string;
                        email: string;
                    };
                } | null;
                brands: {
                    id: number;
                    name: string;
                }[];
                regions: {
                    id: number;
                    name: string;
                }[];
                areas: {
                    id: number;
                    name: string;
                }[];
                countries: {
                    id: number;
                    name: string;
                }[];
                property_types: {
                    id: number;
                    name: string;
                }[];
            }[];
        }[];
    }[];
};

export type AdminUnpublishedDocument = {
    id: number;
    name: string;
    chapters: AdminUnpublishedDocumentChapter[];
};
export type AdminUnpublishedDocumentChapter = {
    id: number;
    name: string;
    introductionText: AdminUnpublishedDocumentChapterIntroductionText;
    sections: AdminUnpublishedDocumentChapterSection[];
};
export type AdminUnpublishedDocumentChapterSection = {
    id: number;
    name: string;
    isLandscape: boolean;
    baseText: AdminUnpublishedDocumentChapterSectionBaseText,
    variants: AdminUnpublishedDocumentChapterSectionVariant[];
};

export type AdminUnpublishedDocumentChapterIntroductionText = {
    body: string;
    isPublished: boolean;
    version: {
        id: number;
        description: string;
        body: string;
        createdAt: string;
        author: {
            id: number;
            fullName: string;
            email: string;
        };
    } | null;
};

export type AdminUnpublishedDocumentChapterSectionBaseText = {
    body: string;
    isPublished: boolean;
    version: {
        id: number;
        description: string;
        body: string;
        createdAt: string;
        author: {
            id: number;
            fullName: string;
            email: string;
        };
    } | null;
};
export type AdminUnpublishedDocumentChapterSectionVariant = {
    id: number;
    name: string;
    body: string;
    isDefault: boolean;
    isPublished: boolean;
    version: AdminUnpublishedDocumentChapterSectionVariantVersion | null;
    brands: {
        id: number;
        name: string;
    }[];
    regions: {
        id: number;
        name: string;
    }[];
    areas: {
        id: number;
        name: string;
    }[];
    countries: {
        id: number;
        name: string;
    }[];
    propertyTypes: {
        id: number;
        name: string;
    }[];
};
export type AdminUnpublishedDocumentChapterSectionVariantVersion = {
    id: number;
    description: string;
    body: string;
    createdAt: string;
    author: {
        id: number;
        fullName: string;
        email: string;
    };
};

function transformGetUnpublishedDocumentResponse(raw: GetUnpublishedDocumentApiResponse): AdminUnpublishedDocument {
    return {
        id: raw.id,
        name: raw.name,
        chapters: raw.chapters.map((chapter) => ({
            id: chapter.id,
            name: chapter.name,
            introductionText: {
                body: chapter.introduction_text.body || '',
                isPublished: chapter.introduction_text.is_published,
                version: chapter.introduction_text.version ? formatVersion(chapter.introduction_text.version) : null,
            },
            sections: chapter.sections.map((section) => ({
                id: section.id,
                name: section.name,
                isLandscape: section.is_landscape,
                baseText: {
                    body: section.base_text.body || '',
                    isPublished: section.base_text.is_published,
                    version: section.base_text.version ? formatVersion(section.base_text.version) : null,
                },
                variants: section.variants.map((variant) => ({
                    id: variant.id,
                    name: variant.name,
                    body: variant.body || '',
                    isDefault: variant.is_default,
                    isPublished: variant.is_published,
                    version: variant.version ? formatVersion(variant.version) : null,
                    brands: variant.brands,
                    regions: variant.regions,
                    areas: variant.areas,
                    countries: variant.countries,
                    propertyTypes: variant.property_types,
                })),
            })),
        })),
    };
}

export const unpublishedAdminApi = createApi({
    reducerPath: 'unpublished-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getUnpublishedDocument: builder.query<AdminUnpublishedDocument, number>({
            query: (id: number) => `/documents/${id}/unpublished`,
            transformResponse(raw: GetUnpublishedDocumentApiResponse) {
                return transformGetUnpublishedDocumentResponse(raw);
            },
        }),
    }),
});

export const {
    useGetUnpublishedDocumentQuery: useGetDocumentUnpublished,
} = unpublishedAdminApi;
