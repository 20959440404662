import React from 'react';

import classnames from 'classnames/bind';

import LoginView from '@PAGES/common/login/components/LoginView';

import styles from './Login.module.scss';

const cx: CX = classnames.bind(styles);

function LoginPage() {
    return (
        <div className={cx('page')}>
            <LoginView />
        </div>
    );
}

export default LoginPage;
