import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';

type BrandsApiResponse = {
    id: number;
    name: string;
};

export const brandApi = createApi({
    reducerPath: 'brand-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAll: builder.query<BrandsApiResponse[], void>({
            query: () => ({
                url: '/brands/',
            }),
            transformResponse: (raw: BrandsApiResponse[]) => raw.sort((a, b) => (a.name > b.name ? 1 : -1)),
            keepUnusedDataFor: 60 * 5, // 5 minutes
        }),
    }),
});

export const {
    useGetAllQuery: useGetAllBrands,
} = brandApi;
