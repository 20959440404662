import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { SingleValue } from 'react-select';

import classnames from 'classnames/bind';

import { AdminSingleChapterMeta } from '@INTERFACES/admin-api/chapters';

import { ChapterSelectedOptions } from '@PAGES/admin/edit-chapter/types/ChapterSelectedOptions';

import BasicInput from '@COMPONENTS/COMMON/inputs/input/BasicInput';
import BasicSelect from 'src/view/COMPONENTS/COMMON/inputs/select/inputs/BasicSelect';
import BasicCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/BasicCheckbox';
import TagCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/TagCheckbox';

import styles from './FiltersView.module.scss';

const cx: CX = classnames.bind(styles);

function FiltersView(props: Props) {
    const {
        chapterMeta,
        searchValue,
        isOnlyDrafts,
        selectedTagsIds,
        setSearchValue,
        setSelectedOptions,
        setOnlyDrafts,
        setSelectedTagsIds,
        selectedOptions,
    } = props;

    const {
        filters: {
            brands = [],
            regions = [],
            areas = [],
            countries = [],
            propertyTypes = [],
        },
        tags,
    } = (chapterMeta || { filters: {}, tags: [], chapters: [] });

    function handleInputChange(val: string) {
        setSearchValue(val);
    }

    return (
        <div className={cx('filters-view')}>
            <div className={cx('sidebar-title')}>
                Filter sections
            </div>

            <div className={cx('input-wrapper')}>
                <BasicInput
                    value={searchValue}
                    label="Search"
                    placeholder="Search by section name"
                    onChange={(val: string) => {
                        handleInputChange(val);
                    }}
                />
            </div>

            <div className={cx('delimiter')} />

            <div className={cx('properties')}>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Brand"
                        placeholder="Choose Brand"
                        options={brands.map((data) => ({
                            value: data!.id,
                            label: data!.name,
                        }))}
                        value={selectedOptions.brand}
                        onChange={(val: SingleValue<any>) => {
                            setSelectedOptions({
                                ...selectedOptions,
                                brand: val,
                            });
                        }}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Region"
                        placeholder="Choose Region"
                        options={regions.map((data) => ({
                            value: data!.id,
                            label: data!.name,
                        }))}
                        value={selectedOptions.region}
                        onChange={(val: SingleValue<any>) => {
                            setSelectedOptions({
                                ...selectedOptions,
                                region: val,
                            });
                        }}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <div className={cx('select-wrapper')}>
                        <BasicSelect
                            title="Area"
                            placeholder="Choose Area"
                            options={areas.map((data) => ({
                                value: data!.id,
                                label: data!.name,
                            }))}
                            value={selectedOptions.area}
                            onChange={(val: SingleValue<any>) => {
                                setSelectedOptions({
                                    ...selectedOptions,
                                    area: val,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Country"
                        placeholder="Choose Country"
                        options={countries.map((data) => ({
                            value: data!.id,
                            label: data!.name,
                        }))}
                        isSearchable
                        value={selectedOptions.country}
                        onChange={(val: SingleValue<any>) => {
                            setSelectedOptions({
                                ...selectedOptions,
                                country: val,
                            });
                        }}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Type"
                        placeholder="Choose Property Type"
                        options={propertyTypes.map((data) => ({
                            value: data!.id,
                            label: data!.name,
                        }))}
                        value={selectedOptions.propertyType}
                        onChange={(val: SingleValue<any>) => {
                            setSelectedOptions({
                                ...selectedOptions,
                                propertyType: val,
                            });
                        }}
                    />
                </div>
            </div>

            <div className={cx('delimiter')} />

            <div className={cx('drafts-checkbox-wrapper')}>
                <BasicCheckbox
                    title="Show drafts only"
                    fontSize={14}
                    fontWeight={600}
                    checked={isOnlyDrafts}
                    onChange={() => {
                        setOnlyDrafts((val: boolean) => !val);
                    }}
                />
            </div>

            <div className={cx('delimiter')} />

            {
                tags.length > 0
                && (
                    <div className={cx('tags')}>
                        <div className={cx('tags-title')}>
                            Tags
                        </div>
                        <div className={cx('tags-list')}>
                            {
                                tags.map((tag) => {
                                    const key = tag.id;

                                    const checked = selectedTagsIds.has(tag.id);

                                    return (
                                        <div
                                            key={key}
                                            className={cx('tag-item')}
                                        >
                                            <TagCheckbox
                                                title={tag.name}
                                                checked={checked}
                                                onChange={(val: boolean) => {
                                                    const set = new Set(selectedTagsIds);

                                                    if (val) {
                                                        set.add(tag.id);
                                                    } else {
                                                        set.delete(tag.id);
                                                    }

                                                    setSelectedTagsIds(set);
                                                }}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}

FiltersView.propTypes = {
    searchValue: PropTypes.string.isRequired,
    selectedTagsIds: PropTypes.instanceOf(Set<number>).isRequired,
    isOnlyDrafts: PropTypes.bool.isRequired,
    setSearchValue: PropTypes.func.isRequired,
    setSelectedOptions: PropTypes.func.isRequired,
    setOnlyDrafts: PropTypes.func.isRequired,
    setSelectedTagsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof FiltersView.propTypes> & {
    chapterMeta: AdminSingleChapterMeta | null,
    selectedOptions: ChapterSelectedOptions,
};

export default FiltersView;
