import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';

// TODO unused ?
type VariantsApiResponse = {
    id: number,
    section: {
        id: number,
        is_include_by_default: boolean,
        chapter: {
            id: number,
            order: number,
            document: {
                id: number,
                name: string,
                document_type: number
            }
        }
    },
    brand: {
        id: number,
        name: string
    },
    region: {
        id: number,
        name: string
    },
    country: {
        id: number,
        name: string
    },
    property: number,
    is_default: boolean,
    json_view: any
};

export const variantApi = createApi({
    reducerPath: 'variant-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAll: builder.query<VariantsApiResponse[], void>({
            query: () => '/variants/',
        }),
        getById: builder.query<VariantsApiResponse, number>({
            query: (id: number) => `/variants/${id}/`,
        }),
    }),
});

export const {
    useGetAllQuery: useGetAllVariants,
    useGetByIdQuery: useGetVariantById,
} = variantApi;
