import type {
    ControlProps, CSSObjectWithLabel, OptionProps, SingleValueProps,
} from 'react-select';

export const selectBaseStyles = {
    control<T, IsMulti extends boolean>(base: CSSObjectWithLabel, props: ControlProps<T, IsMulti>): CSSObjectWithLabel {
        return {
            ...base,
            border: '1px solid',
            borderColor: '#a7b5be',
            borderRadius: 5,
            height: 36,
            minHeight: 36,
            fontSize: 12,
            transition: 'none',
            background: props.isDisabled ? 'rgb(242, 242, 242)' : 'white',
            cursor: props.isDisabled ? 'default' : 'pointer',
            '&:hover': {
                boxShadow: props.isFocused ? 'none' : 'none',
            },
        };
    },
    menu(base: CSSObjectWithLabel): CSSObjectWithLabel {
        return {
            ...base,
            background: 'white',
            marginTop: 4,
            marginBottom: 4,
            overflow: 'hidden',
            borderRadius: 5,
            border: '1px solid #a7b5be',
            zIndex: 10,
        };
    },
    option<T, IsMulti extends boolean>(base: CSSObjectWithLabel, props: OptionProps<T, IsMulti>): CSSObjectWithLabel {
        return {
            ...base,
            backgroundColor: 'white',
            padding: '12px 16px',
            fontSize: 12,
            color: props.isSelected || props.isFocused ? '#202020' : '#a7b5be',
            cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        };
    },
    placeholder(base: CSSObjectWithLabel): CSSObjectWithLabel {
        return {
            ...base,
            color: '#adb4bd',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        };
    },
    valueContainer(base: CSSObjectWithLabel): CSSObjectWithLabel {
        return {
            ...base,
            padding: '0 16px',
        };
    },
    singleValue<T, IsMulti extends boolean>(
        base: CSSObjectWithLabel,
        props: SingleValueProps<T, IsMulti>,
    ): CSSObjectWithLabel {
        return {
            ...base,
            color: props.isDisabled ? '#adb4bd' : '#202020',
        };
    },
};
