import { AdminSingleChapter } from '@INTERFACES/admin-api/chapters';
import { ChapterSelectedOptions } from '@PAGES/admin/edit-chapter/types/ChapterSelectedOptions';

export default function getFilteredByOptionsData(
    chapterData: AdminSingleChapter,
    options: ChapterSelectedOptions,
): AdminSingleChapter {
    const entries = Object.entries(options);

    const isEmptyOptions = entries.every(([, value]) => value === null);

    if (isEmptyOptions) return chapterData;

    if (chapterData.data) {
        const { data, meta } = chapterData;

        const { sections } = data;

        const filtered = {
            ...data,
            sections: sections.map((section) => {
                const { variants } = section;

                return {
                    ...section,
                    variants: variants.filter((variant) => {
                        if (options.brand !== null) {
                            const brand = variant.brands.find((b) => b.id === options.brand.value);

                            if (!brand) {
                                return false;
                            }
                        }

                        if (options.region !== null) {
                            const region = variant.regions.find((b) => b.id === options.region.value);

                            if (!region) {
                                return false;
                            }
                        }

                        if (options.area !== null) {
                            const area = variant.areas.find((b) => b.id === options.area.value);

                            if (!area) {
                                return false;
                            }
                        }

                        if (options.country !== null) {
                            const country = variant.countries.find((b) => b.id === options.country.value);

                            if (!country) {
                                return false;
                            }
                        }

                        if (options.propertyType !== null) {
                            const propertyType = variant.propertyTypes.find((b) => b.id === options.propertyType.value);

                            if (!propertyType) {
                                return false;
                            }
                        }

                        return true;
                    }),
                };
            }).filter((section) => section.variants.length > 0),
        };

        return {
            data: filtered,
            meta,
        };
    }

    return chapterData;
}
