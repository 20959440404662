import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';

type DocumentsApiResponse = {
    id: number;
    name: string;
    image: string | null;
    document_type: number;
    chapters: {
        id: number;
        name: string;
        order: number;
    }[];
};

export const documentApi = createApi({
    reducerPath: 'document-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAll: builder.query<DocumentsApiResponse[], void>({
            query: () => ({
                url: '/documents/',
            }),
            transformResponse: (raw: DocumentsApiResponse[]) => raw.sort((a, b) => (a.name > b.name ? 1 : -1)),
        }),
    }),
});

export const {
    useGetAllQuery: useGetAllDocuments,
} = documentApi;
