import React, { useMemo } from 'react';

import classnames from 'classnames/bind';

import { formatPropertiesList } from '@HELPERS/formatPropertiesList';
import styles from './VariantHeader.module.scss';

const cx: CX = classnames.bind(styles);

function VariantHeader(props: Props) {
    const { variant } = props;

    const variantOptions = useMemo(() => [
        {
            id: 1,
            title: 'Brands',
            data: variant.brands,
        },
        {
            id: 2,
            title: 'Regions',
            data: variant.regions,
        },
        {
            id: 3,
            title: 'Areas',
            data: variant.areas,
        },
        {
            id: 4,
            title: 'Countries',
            data: variant.countries,
        },
        {
            id: 5,
            title: 'Types',
            data: variant.propertyTypes,
        },
    ], [variant]);

    return (
        <div className={cx('variant-header')}>
            {
                !variant.isDefault
                    ? (
                        <div className={cx('variant-properties')}>
                            {
                                variantOptions.map((property) => {
                                    const { id, title, data } = property;

                                    return (
                                        <div
                                            key={id}
                                            className={cx('property')}
                                        >
                                            <div className={cx('property-title')}>
                                                {title}
                                            </div>
                                            <div className={cx('property-value')}>
                                                {formatPropertiesList(data)}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ) : (
                        <div className={cx('default-variant')}>
                            Default
                        </div>
                    )
            }
        </div>
    );
}

type PropertyType = {
    id: number;
    name: string
};

type Props = {
    variant: {
        isDefault: boolean,
        brands: PropertyType[],
        regions: PropertyType[],
        areas: PropertyType[],
        countries: PropertyType[],
        propertyTypes: PropertyType[],
    }
};

export default VariantHeader;
