import React, { MouseEvent } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ReactComponent as SkipIcon } from '@ICONS/skip-icon.svg';

import styles from './SkipButton.module.scss';

const cx: CX = classnames.bind(styles);

function SkipButton(props: Props) {
    const { onClick } = props;

    function handleClick(event: MouseEvent<HTMLButtonElement>) {
        onClick(event);
    }

    return (
        <div className={cx('skip-button')}>
            <button
                type="button"
                className={cx('clear-button', 'skip')}
                onClick={handleClick}
            >
                <div className={cx('icon')}>
                    <SkipIcon />
                </div>
                <div>
                    Skip Step
                </div>
            </button>
        </div>
    );
}

SkipButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof SkipButton.propTypes>;

export default SkipButton;
