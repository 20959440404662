import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import { logsAdminApi } from '../admin-api/logs.admin-api';

type DownloadDocumentApiResponse = {
    url: string;
};

type DownloadParams = {
    document: number;
    variant: number[];
    section: number[];
    chapter: number[];
    expirationTime: number;
    propertyName: string;
    brand: number | undefined;
    region: number | undefined;
    area: number | undefined;
    country: number | undefined;
    propertyType: number | undefined;
    isPartial: boolean;
    watermarkText: string;
};

export const downloadDocumentApi = createApi({
    reducerPath: 'download-document-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        download: builder.mutation<DownloadDocumentApiResponse, DownloadParams>({
            query: (params: DownloadParams) => {
                const formData = new FormData();

                formData.append('document', params.document.toString());
                formData.append('variant', params.variant.join(','));
                formData.append('section', params.section.join(','));
                formData.append('chapter', params.chapter.join(','));
                formData.append('expiration_time', params.expirationTime.toString());
                formData.append('property_name', params.propertyName || '');

                formData.append('brand', params.brand ? params.brand.toString() : '');
                formData.append('region', params.region ? params.region.toString() : '');
                formData.append('area', params.area ? params.area.toString() : '');
                formData.append('country', params.country ? params.country.toString() : '');
                formData.append('property_type', params.propertyType ? params.propertyType.toString() : '');

                formData.append('is_partial_generation', params.isPartial.toString());

                formData.append('watermark_text', params.watermarkText);

                return ({
                    url: '/download/',
                    method: 'POST',
                    body: formData,
                });
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(logsAdminApi.util.resetApiState());
                } catch (e) {
                    //
                }
            },
        }),
    }),
});

export const {
    useDownloadMutation: useDownloadDocument,
} = downloadDocumentApi;
