import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import { RESULTS_PAGE_SIZE } from '@CONSTANTS/LOGS.constant';

type LogsApiResponse = {
    count: number;
    results: {
        id: number;
        action: number;
        user: {
            id: number;
            email: string;
            full_name: string;
        };
        created_at: string;
    }[];
};

type LogsParams = {
    page: number;
    actions: number[];
};

export type AdminLogs = {
    count: number;
    totalPages: number;
    results: AdminLogsResult[];
};

export type AdminLogsResult = {
    id: number;
    action: number;
    user: AdminLogsUser;
    createdAt: string;
};

export type AdminLogsUser = {
    id: number;
    email: string;
    fullName: string;
};

export const logsAdminApi = createApi({
    reducerPath: 'logs-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        logs: builder.query<AdminLogs, LogsParams>({
            query: (params) => ({
                url: '/logs/',
                params: {
                    size: RESULTS_PAGE_SIZE,
                    page: params.page,
                    action__in: params.actions.join(','),
                },
            }),
            transformResponse(raw: LogsApiResponse) {
                return {
                    count: raw.count,
                    totalPages: Math.ceil(raw.count / RESULTS_PAGE_SIZE),
                    results: raw.results.map((result) => ({
                        id: result.id,
                        action: result.action,
                        user: {
                            id: result.user.id,
                            email: result.user.email,
                            fullName: result.user.full_name,
                        },
                        createdAt: result.created_at,
                    })),
                };
            },
        }),
    }),
});

export const {
    useLazyLogsQuery: useLogsLazy,
} = logsAdminApi;
