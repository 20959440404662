import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import { AdminSingleChapterDataSectionVariant } from '@INTERFACES/admin-api/chapters';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import CircleCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/CircleCheckbox';
import VariantHeader from '@MODALS/Publications/components/VariantHeader';
import PublicationPair from '@COMPONENTS/SHARED/PublicationPair';

import styles from './Variant.module.scss';

const cx: CX = classNames.bind(styles);

function Variant(props: Props) {
    const {
        variant,
        isLandscape,
        selectedVariantsIds,
        setSelectedVariantsIds,
    } = props;

    const oldVersion = variant.latestVersion?.body || null;

    const newVersion = variant.body;

    const contentIsEmpty = isContentEmptyCheck(newVersion);

    return (
        <div className={cx('variant')}>
            <div className={cx('checkbox-wrapper')}>
                <div className={cx('checkbox')}>
                    <CircleCheckbox
                        disabled={contentIsEmpty}
                        checked={selectedVariantsIds.has(variant.id)}
                        onChange={(val: boolean) => {
                            const set = new Set(selectedVariantsIds);

                            if (val) {
                                set.add(variant.id);
                            } else {
                                set.delete(variant.id);
                            }

                            setSelectedVariantsIds(set);
                        }}
                    />
                </div>
            </div>
            <div className={cx('variant-body')}>
                <VariantHeader
                    variant={{
                        isDefault: variant.isDefault,
                        brands: variant.brands,
                        regions: variant.regions,
                        areas: variant.areas,
                        countries: variant.countries,
                        propertyTypes: variant.propertyTypes,
                    }}
                />
                <PublicationPair
                    contentIsEmpty={contentIsEmpty}
                    isLandscape={isLandscape}
                    pair={{
                        oldVersion,
                        newVersion,
                    }}
                />
            </div>
        </div>
    );
}

Variant.propTypes = {
    isLandscape: PropTypes.bool.isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Variant.propTypes> & {
    variant: AdminSingleChapterDataSectionVariant,
};

export default Variant;
