import React from 'react';

import classnames from 'classnames/bind';

import { AdminSingleChapterDataSectionVariant } from '@INTERFACES/admin-api/chapters';

import Warning from '@COMPONENTS/SHARED/Warning';

import { formatPropertiesList } from '@HELPERS/formatPropertiesList';
import styles from './Variant.module.scss';

const cx: CX = classnames.bind(styles);

function getVariantWarning(variant: AdminSingleChapterDataSectionVariant) {
    if (variant.latestVersion === null) {
        return 'Variant is not published';
    }

    return 'Variant has unpublished changes';
}

function Variant(props: Props) {
    const {
        variant,
    } = props;

    return (
        <div className={cx('variant')}>

            {
                !variant.isPublished
                && (
                    <div className={cx('variant-warning-wrapper')}>
                        <Warning text={getVariantWarning(variant)} />
                    </div>
                )
            }

            <div className={cx('variant-item-header')}>
                <div className={cx('variant-item-title')}>
                    <div className={cx('title')}>
                        {variant.name}
                    </div>
                </div>
                {
                    variant.isDefault
                    && (
                        <div className={cx('is-default-title')}>
                            Default
                        </div>
                    )
                }
            </div>

            {
                !variant.isDefault
                && (
                    <div className={cx('variant-item-properties')}>
                        {
                            [
                                {
                                    title: 'Brands',
                                    data: variant.brands,
                                },
                                {
                                    title: 'Regions',
                                    data: variant.regions,
                                },
                                {
                                    title: 'Areas',
                                    data: variant.areas,
                                },
                                {
                                    title: 'Countries',
                                    data: variant.countries,
                                },
                                {
                                    title: 'Types',
                                    data: variant.propertyTypes,
                                },
                            ].filter(({ data }) => data.length > 0)
                                .map((property) => {
                                    const { title, data } = property;

                                    return (
                                        <div
                                            key={title}
                                            className={cx('property')}
                                        >
                                            <div className={cx('property-title')}>
                                                {title}
                                            </div>
                                            <div className={cx('property-value')}>
                                                {formatPropertiesList(data)}
                                            </div>
                                        </div>
                                    );
                                })
                        }
                    </div>
                )
            }

            {
                variant.tags.length > 0
                && (
                    <div className={cx('variant-item-tags')}>
                        <div className={cx('tags-title')}>
                            Tags
                        </div>
                        <div className={cx('tags')}>
                            {
                                variant.tags.map((tag) => {
                                    const tagKey = tag.id;

                                    return (
                                        <div
                                            key={tagKey}
                                            className={cx('tag-item')}
                                        >
                                            {tag.name}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}

type Props = {
    variant: AdminSingleChapterDataSectionVariant,
};

export default Variant;
