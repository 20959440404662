import React from 'react';

import classnames from 'classnames/bind';

import { ReactComponent as CheckIcon } from '@ICONS/checkbox-icon-2.svg';

import styles from './CheckboxIcon.module.scss';

const cx = classnames.bind(styles);

function CheckboxIcon({
    checked, //
    disabled = false,
}: Props) {
    return (
        <div
            className={cx('checkbox-icon', {
                checked: checked === true,
                unchecked: checked === false,
                'half-checked': checked === 'half-checked',
                disabled,
            })}
        >
            {checked === 'half-checked' ? <div className={cx('unchecked-view')} /> : <CheckIcon />}
        </div>
    );
}

CheckboxIcon.defaultProps = {
    disabled: false,
};

interface Props {
    checked: boolean | 'half-checked';
    disabled?: boolean;
}

export default CheckboxIcon;
