import React, { useMemo } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import { ReactComponent as EditIcon } from '@ICONS/edit-icon-2.svg';

import Warning from '@COMPONENTS/SHARED/Warning';
import LatestUpdates from '@MODALS/Previews/components/LatestUpdates';

import { formatPropertiesList } from '@HELPERS/formatPropertiesList';
import styles from './VariantInfo.module.scss';

const cx: CX = classnames.bind(styles);

type Variant = {
    id: number;
    isDefault: boolean;
    isPublished: boolean;
    latestVersion: Version,
    brands: VariantProperty[];
    regions: VariantProperty[];
    areas: VariantProperty[];
    countries: VariantProperty[];
    propertyTypes: VariantProperty[];
};

type Version = {
    id: number;
    description: string;
    createdAt: string;
    author: {
        id: number;
        fullName: string;
        email: string;
    };
} | null;

type VariantProperty = {
    id: number;
    name: string;
};

function VariantInfo(props: Props) {
    const {
        top,
        sectionId,
        variant,
    } = props;

    const navigate = useNavigate();

    const variantOptions = useMemo(() => [
        {
            title: 'Brands',
            data: variant.brands,
        },
        {
            title: 'Regions',
            data: variant.regions,
        },
        {
            title: 'Areas',
            data: variant.areas,
        },
        {
            title: 'Countries',
            data: variant.countries,
        },
        {
            title: 'Types',
            data: variant.propertyTypes,
        },
    ].filter(({ data }) => data !== null), [variant]);

    const includedToVersionControl = !!variant.latestVersion?.description;

    function getWarning() {
        if (variant.latestVersion === null) {
            return 'Variant is not published';
        }

        return 'Variant has unpublished changes';
    }

    return (
        <div className={cx('variant-info')}>
            <div
                className={cx('info')}
                style={{
                    top,
                }}
            >
                {
                    !variant.isPublished
                    && (
                        <>
                            <div className={cx('warning-wrapper')}>
                                <Warning text={getWarning()} />
                            </div>
                            <div className={cx('delimiter')} />
                        </>
                    )
                }

                <div className={cx('edit-button-wrapper')}>
                    <button
                        type="button"
                        className={cx('clear-button', 'edit-button')}
                        onClick={() => {
                            navigate(ROUTES.admin.sections.id.get(sectionId, variant.id));
                        }}
                    >
                        <EditIcon
                            width={20}
                            height={20}
                        />
                        <div className={cx('edit-button-title')}>
                            Edit variant
                        </div>
                    </button>
                </div>

                {
                    !variant.isDefault
                        ? (
                            <div className={cx('variant-item-properties')}>
                                {
                                    variantOptions.map((property, index) => {
                                        const { title, data } = property;

                                        return (
                                            <div
                                                key={title + index.toString()}
                                                className={cx('property')}
                                            >
                                                <div className={cx('property-title')}>
                                                    {title}
                                                </div>
                                                <div className={cx('property-value')}>
                                                    {formatPropertiesList(data)}
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        ) : (
                            <div className={cx('default-variant')}>
                                Default
                            </div>
                        )
                }

                {
                    variant.latestVersion
                    && (
                        <>
                            <div className={cx('delimiter')} />

                            <LatestUpdates
                                includedToVersionControl={includedToVersionControl}
                                version={{
                                    description: variant.latestVersion.description || '',
                                    createdAt: variant.latestVersion.createdAt,
                                    author: {
                                        fullName: variant.latestVersion.author.fullName,
                                        email: variant.latestVersion.author.email,
                                    },
                                }}
                            />
                        </>
                    )
                }
            </div>
        </div>
    );
}

VariantInfo.defaultProps = {
    top: 30,
};

VariantInfo.propTypes = {
    sectionId: PropTypes.number.isRequired,
    top: PropTypes.number,
};

type Props = InferProps<typeof VariantInfo.propTypes> & typeof VariantInfo.defaultProps & {
    variant: Variant,
};

export default VariantInfo;
