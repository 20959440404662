import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';

type SearchParams = {
    id: number;
    q: string;
};

type SearchInsideDocumentApiResponse = {
    data: {
        id: number;
        name: string;
        chapters: {
            id: number;
            name: string;
            body: string | null;
            sections: {
                id: number;
                name: string;
                body: string | null;
                is_landscape: boolean;
                variants: {
                    id: number;
                    name: string;
                    body: string;
                    is_landscape: boolean;
                    is_default: boolean;
                    brands: {
                        id: number;
                        name: string;
                    }[];
                    regions: {
                        id: number;
                        name: string;
                    }[];
                    areas: {
                        id: number;
                        name: string;
                    }[];
                    countries: {
                        id: number;
                        name: string;
                    }[];
                    property_types: {
                        id: number;
                        name: string;
                    }[];
                }[];
            }[];
        }[];
    };
    meta: AdminSearchInsideMeta;
};

type SearchInsideChapterApiResponse = {
    data: {
        id: number;
        name: string;
        body: string | null;
        sections: {
            id: number;
            name: string;
            body: string | null;
            is_landscape: boolean;
            variants: {
                id: number;
                name: string;
                body: string;
                is_landscape: boolean;
                is_default: boolean;
                brands: {
                    id: number;
                    name: string;
                }[];
                regions: {
                    id: number;
                    name: string;
                }[];
                areas: {
                    id: number;
                    name: string;
                }[];
                countries: {
                    id: number;
                    name: string;
                }[];
                property_types: {
                    id: number;
                    name: string;
                }[];
            }[];
        }[];
    };
    meta: AdminSearchInsideMeta;
};

type SearchInsideSectionApiResponse = {
    data: {
        id: number;
        name: string;
        body: string | null;
        is_landscape: boolean;
        variants: {
            id: number;
            name: string;
            body: string;
            is_landscape: boolean;
            is_default: boolean;
            brands: {
                id: number;
                name: string;
            }[];
            regions: {
                id: number;
                name: string;
            }[];
            areas: {
                id: number;
                name: string;
            }[];
            countries: {
                id: number;
                name: string;
            }[];
            property_types: {
                id: number;
                name: string;
            }[];
        }[];
    };
    meta: AdminSearchInsideMeta;
};

export type AdminSearchInsideDocument = {
    data: AdminDocumentSearchResultData;
    meta: AdminSearchInsideMeta;
};

export type AdminSearchInsideChapter = {
    data: AdminChapterSearchResultData;
    meta: AdminSearchInsideMeta;
};
export type AdminSearchInsideSection = {
    data: AdminSectionSearchResultData;
    meta: AdminSearchInsideMeta;
};

export type AdminSearchInsideMeta = {
    count: number;
    query: string;
};

export type AdminDocumentSearchResultData = {
    id: number;
    name: string;
    chapters: AdminChapterSearchResultData[];
};

export type AdminChapterSearchResultData = {
    id: number;
    name: string;
    body: string | null;
    sections: AdminSectionSearchResultData[];
};

export type AdminSectionSearchResultData = {
    id: number;
    name: string;
    body: string | null;
    isLandscape: boolean;
    variants: AdminVariantSearchResult[];
};

export type AdminVariantSearchResult = {
    id: number;
    name: string;
    body: string;
    isLandscape: boolean;
    isDefault: boolean;
    brands: {
        id: number;
        name: string;
    }[];
    regions: {
        id: number;
        name: string;
    }[];
    areas: {
        id: number;
        name: string;
    }[];
    countries: {
        id: number;
        name: string;
    }[];
    propertyTypes: {
        id: number;
        name: string;
    }[];
};

export const searchAdminApi = createApi({
    reducerPath: 'search-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        searchInsideDocument: builder.query<AdminSearchInsideDocument, SearchParams>({
            query: (params) => ({
                url: `/documents/${params.id}/search/`,
                params: {
                    q: params.q,
                },
            }),
            transformResponse(raw: SearchInsideDocumentApiResponse) {
                return {
                    data: {
                        id: raw.data.id,
                        name: raw.data.name,
                        chapters: raw.data.chapters.map((chapter) => ({
                            id: chapter.id,
                            name: chapter.name,
                            body: chapter.body,
                            sections: chapter.sections.map((section) => ({
                                id: section.id,
                                name: section.name,
                                body: section.body,
                                isLandscape: section.is_landscape,
                                variants: section.variants.map((variant) => ({
                                    id: variant.id,
                                    name: variant.name,
                                    body: variant.body,
                                    isLandscape: variant.is_landscape,
                                    isDefault: variant.is_default,
                                    brands: variant.brands,
                                    regions: variant.regions,
                                    areas: variant.areas,
                                    countries: variant.countries,
                                    propertyTypes: variant.property_types,
                                })),
                            })),
                        })),
                    },
                    meta: raw.meta,
                };
            },
            keepUnusedDataFor: 0,
        }),
        searchInsideChapter: builder.query<AdminSearchInsideChapter, SearchParams>({
            query: (params) => ({
                url: `/chapters/${params.id}/search/`,
                params: {
                    q: params.q,
                },
            }),
            transformResponse(raw: SearchInsideChapterApiResponse) {
                return {
                    data: {
                        id: raw.data.id,
                        name: raw.data.name,
                        body: raw.data.body,
                        sections: raw.data.sections.map((section) => ({
                            id: section.id,
                            name: section.name,
                            body: section.body,
                            isLandscape: section.is_landscape,
                            variants: section.variants.map((variant) => ({
                                id: variant.id,
                                name: variant.name,
                                body: variant.body,
                                isLandscape: variant.is_landscape,
                                isDefault: variant.is_default,
                                brands: variant.brands,
                                regions: variant.regions,
                                areas: variant.areas,
                                countries: variant.countries,
                                propertyTypes: variant.property_types,
                            })),
                        })),
                    },
                    meta: raw.meta,
                };
            },
            keepUnusedDataFor: 0,
        }),
        searchInsideSection: builder.query<AdminSearchInsideSection, SearchParams>({
            query: (params) => ({
                url: `/sections/${params.id}/search/`,
                params: {
                    q: params.q,
                },
            }),
            transformResponse(raw: SearchInsideSectionApiResponse) {
                return {
                    data: {
                        id: raw.data.id,
                        name: raw.data.name,
                        body: raw.data.body,
                        isLandscape: raw.data.is_landscape,
                        variants: raw.data.variants.map((variant) => ({
                            id: variant.id,
                            name: variant.name,
                            body: variant.body,
                            isLandscape: variant.is_landscape,
                            isDefault: variant.is_default,
                            brands: variant.brands,
                            regions: variant.regions,
                            areas: variant.areas,
                            countries: variant.countries,
                            propertyTypes: variant.property_types,
                        })),
                    },
                    meta: raw.meta,
                };
            },
            keepUnusedDataFor: 0,
        }),
    }),
});

export const {
    useLazySearchInsideDocumentQuery: useSearchInsideDocumentLazy,
    useLazySearchInsideChapterQuery: useSearchInsideChapterLazy,
    useLazySearchInsideSectionQuery: useSearchInsideSectionLazy,
} = searchAdminApi;
